<template>
  <ion-page>
    <ion-content :fullscreen="true">

      <Header></Header>

      <div class="container">
        <section id="options" class="list-nav mt-lg mb-md">
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-lg="6">
                  <h4>Editar</h4>
                  <h1>Producto</h1>
                </ion-col>
              </ion-row>
              <ion-row style="margin-top: 1rem">
                <ion-col size="12" size-lg="6">
                  <div class="flex">
                    <ion-button @click="exit" shape="round" style="margin-top: 6px" color="dark" fill="outline">
                        <ion-icon slot="start" :icon="arrowBackOutline"></ion-icon>
                        <ion-label>Volver</ion-label>
                    </ion-button>
                    <ion-button @click="save" :disabled="v$.element.$invalid" color="primary" style="margin-top: 6px; margin-left:0.75rem" shape="round">
                        <ion-icon slot="start" :icon="saveOutline"></ion-icon>
                        <ion-label>Guardar</ion-label>
                    </ion-button>
                  </div>
                </ion-col>
                <ion-col size="12" size-lg="6">
                  <ion-buttons class="options-nav">
                    <ion-toggle v-model="element.active" color="success"></ion-toggle>
                    <ion-button @click="confirmDeleteElement($event)" >
                        <ion-icon color="danger" slot="icon-only" :icon="trashOutline"></ion-icon>
                    </ion-button>
                    <ConfirmPopup></ConfirmPopup>
                  </ion-buttons>
                </ion-col>
              </ion-row>
          </ion-grid>
        </section>

        <section id="content" class="mb-lg">
          <ion-grid>
            <ion-row>
              <ion-col size="12" size-lg="8">
                <ion-grid>
                  <ion-row class="mb-md">
                      <h5 class="edit-title">Detalles</h5>
                      
                      <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.element.name.$errors.length }">
                          <label for="name">Nombre</label>
                          <InputText class="input secondary-input" id="name" placeholder="Nombre del Producto" type="text" v-model.trim.lazy="v$.element.name.$model" />
                          <!-- error message -->
                          <div class="input-errors" v-for="(error, index) of v$.element.name.$errors" :key="index">
                          <h6 class="error-msg">{{ error.$message }}</h6>
                          </div>
                      </ion-col>

                      <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.element.refCode.$errors.length }">
                          <label for="refCode">Código de Referencia</label>
                            <span class="p-input-icon-right" style="width:100%">
                                <i class="pi pi-refresh" style="cursor: pointer" @click="regenerateRefCode('main')" />
                                <InputText class="input" id="refCode" placeholder="AA00000000" type="text" v-model.trim.lazy="v$.element.refCode.$model" />
                            </span>
                          <!-- error message -->
                          <div class="input-errors" v-for="(error, index) of v$.element.refCode.$errors" :key="index">
                          <h6 class="error-msg">{{ error.$message }}</h6>
                          </div>
                      </ion-col>

                      <ion-col size="12" size-lg="6" class="box-input" v-if="productsCategories" :class="{ error: v$.element.category.$errors.length }">
                          <label for="category">Categoría</label>
                          <Dropdown v-model.lazy="v$.element.category.$model" placeholder="Seleccionar" id="category" class="inputSelect secondary-input" :options="productsCategories.filter(x => x.type === 'category')" optionLabel="name" :filter="true" :showClear="true">
                              <template #value="slotProps">
                                  <div class="country-item country-item-value" v-if="slotProps.value">
                                      <div>{{slotProps.value.name}}</div>
                                  </div>
                                  <span v-else>
                                      {{slotProps.placeholder}}
                                  </span>
                              </template>
                              <template #option="slotProps">
                                  <div class="country-item">
                                      <div>{{slotProps.option.name}}</div>
                                  </div>
                              </template>
                          </Dropdown>
                          <!-- error message -->
                          <div class="input-errors" v-for="(error, index) of v$.element.category.$errors" :key="index">
                          <h6 class="error-msg">{{ error.$message }}</h6>
                          </div>
                      </ion-col>

                        <ion-col size="12" size-lg="6" class="box-input" v-if="element.category && productsCategories.filter(x => { if(x.father) {return x.father.name === element.category.name} else {return false} }).length" :class="{ error: v$.element.subcategory.$errors.length }">
                            <label for="subcategory">Subcategoría</label>
                            <Dropdown v-model.lazy="v$.element.subcategory.$model" placeholder="Seleccionar" id="subcategory" class="inputSelect" :options="productsCategories.filter(x => { if(x.father) {return x.father.name === element.category.name} else {return false} })" optionLabel="name" :filter="true" :showClear="true">
                                <template #value="slotProps">
                                    <div class="country-item country-item-value" v-if="slotProps.value">
                                        <div>{{slotProps.value.name}}</div>
                                    </div>
                                    <span v-else>
                                        {{slotProps.placeholder}}
                                    </span>
                                </template>
                                <template #option="slotProps">
                                    <div class="country-item">
                                        <div>{{slotProps.option.name}}</div>
                                    </div>
                                </template>
                            </Dropdown>
                            <!-- error message -->
                            <div class="input-errors" v-for="(error, index) of v$.element.subcategory.$errors" :key="index">
                            <h6 class="error-msg">{{ error.$message }}</h6>
                            </div>
                        </ion-col>
                        <ion-col size="12" size-lg="6" v-if="!element.category || !productsCategories.filter(x => { if(x.father) {return x.father.name === element.category.name} else {return false} }).length"></ion-col>

                        <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.element.price.$errors.length }">
                            <label for="price">Precio (I.V.A. Incl.)</label>
                            <InputNumber id="price" class="inputNumber secondary-input" v-model.lazy="v$.element.price.$model" :min="0" showButtons mode="currency" currency="EUR" locale="es-ES" />

                            <!-- error message -->
                            <div class="input-errors" v-for="(error, index) of v$.element.price.$errors" :key="index">
                            <h6 class="error-msg">{{ error.$message }}</h6>
                            </div>
                        </ion-col>

                        <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.element.cost.$errors.length }">
                            <label for="price">Coste (I.V.A. Excl.)</label>
                            <InputNumber id="price" class="inputNumber" v-model.lazy="v$.element.cost.$model" :min="0" showButtons mode="currency" currency="EUR" locale="es-ES" />

                            <!-- error message -->
                            <div class="input-errors" v-for="(error, index) of v$.element.cost.$errors" :key="index">
                            <h6 class="error-msg">{{ error.$message }}</h6>
                            </div>
                        </ion-col>

                        <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.element.stock.$errors.length }">
                            <label for="stock">Stock Total</label>
                            <InputNumber id="stock" class="inputNumber secondary-input" v-model.lazy="v$.element.stock.$model" :min="0" showButtons  />

                            <!-- error message -->
                            <div class="input-errors" v-for="(error, index) of v$.element.stock.$errors" :key="index">
                            <h6 class="error-msg">{{ error.$message }}</h6>
                            </div>
                        </ion-col>


                        <ion-col size="12" size-lg="6" v-if="!element.category"></ion-col>

                        <ion-col size="12" class="box-input" :class="{ error: v$.element.description.$errors.length }">
                            <label for="description">Descripción</label>
                            <Editor v-model.trim.lazy="v$.element.description.$model" id="description" placeholder="Descripción del Producto" editorStyle="min-height: 320px; height: auto"></Editor>
                            <!-- error message -->
                            <div class="input-errors" v-for="(error, index) of v$.element.description.$errors" :key="index">
                            <h6 class="error-msg">{{ error.$message }}</h6>
                            </div>
                        </ion-col>

                        <ion-col size="12" class="box-input" :class="{ error: v$.element.keywords.$errors.length }">
                            <label for="keywords">Palabras Clave</label>
                            <Chips class="inputChips" separator="," id="keywords" placeholder="Palabras clave separadas por coma" v-model.trim.lazy="v$.element.keywords.$model" />
                            <!-- error message -->
                            <div class="input-errors" v-for="(error, index) of v$.element.keywords.$errors" :key="index">
                            <h6 class="error-msg">{{ error.$message }}</h6>
                            </div>
                        </ion-col>
                      </ion-row>

                      <ion-row class="mb-md">
                        <ion-col size="12">
                          <h5 class="edit-title">Imágenes</h5>
                              <FileUpload
                                  mode="basic"
                                  name="image"
                                  :multiple="true"
                                  accept="image/*"
                                  :maxFileSize="5000000"
                                  :customUpload="true"
                                  @uploader="handleImageUpload"
                                  :auto="true"
                                  chooseLabel="Subir Imágenes"
                                  class="uploader"
                                  ref="uploader"
                              />
                                <p style="margin-top: 1rem;" v-if="!images.length">No hay imagen</p>
                                <draggable class="imagenes" :list="images" @change="updateImageList">
                                  <div
                                    class="imagen-container" v-for="image in images" :key="image"
                                  >
                                    <Image :src="image.objectURL" alt="image preview" class="imagen" preview />
                                    <ion-buttons class="imagen-remover">
                                        <ion-button @click="handleImageRemove($event, image.objectURL)">
                                        <ion-icon :icon="closeCircleOutline" color="danger"></ion-icon>
                                        </ion-button>
                                    </ion-buttons>
                                  </div>
                                </draggable>
                          </ion-col>
                      </ion-row>

                      <ion-row class="mb-md">
                        <h5 class="edit-title">Variaciones</h5>
                          <ion-col size="12">
                            <ion-item lines="none">
                                <ion-label><b>Variaciones del Producto</b></ion-label>
                                <ion-buttons slot="end">
                                    <ion-button @click="addVariation" >
                                        <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                                    </ion-button>
                                </ion-buttons>
                            </ion-item>

                        <DataTable :value="element.variations" v-model:expandedRows="expandedRows" @cell-edit-complete="onCellEditVariationComplete" dataKey="key" editMode="cell" class="variations p-datatable-sm edit-datatable" responsiveLayout="scroll">
                            <template #empty>
                                No hay variaciones de este producto
                            </template>
                            
                            <Column :expander="true" style="width: 64px;" />
                            <Column field="name" header="Variación">
                              <template #editor="slotProps">
                                  <InputText class="input" v-model="slotProps.data[slotProps.column.props.field]" />
                              </template>
                            </Column>
                            <Column alignFrozen="right">
                                <template #body="slotProps">
                                    <ion-buttons style="justify-content: flex-end">
                                        <ion-button @click="addOption($event, slotProps.data.key)" >
                                            <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                                        </ion-button>
                                        <ion-button @click="removeVariation($event, slotProps.data.key)" >
                                            <ion-icon slot="icon-only" :icon="removeCircleOutline"></ion-icon>
                                        </ion-button>
                                    </ion-buttons>
                                </template>
                            </Column>
                            <template #expansion="slotProps">
                                <div class="options-subtable">
                                    <DataTable :value="slotProps.data.options" @cell-edit-complete="onCellEditOptionComplete($event, slotProps)" dataKey="key" editMode="cell" class="editable-cells-table" responsiveLayout="scroll">
                                        <Column field="text" header="Opción">
                                          <template #editor="slotProps">
                                              <InputText class="input" v-model="slotProps.data[slotProps.column.props.field]" />
                                          </template>
                                        </Column>
                                        <Column field="price" header="Precio (€)">
                                          <template #editor="slotProps">
                                              <InputNumber id="price" class="inputNumber" :min="0" v-model="slotProps.data[slotProps.column.props.field]" showButtons mode="currency" currency="EUR" locale="es-ES"/>
                                          </template>
                                        </Column>
                                        <Column field="stock" header="Cantidad">
                                          <template #editor="slotProps">
                                              <InputNumber id="price" class="inputNumber" :min="0" v-model="slotProps.data[slotProps.column.props.field]" showButtons mode="decimal" suffix=" Uds." />
                                          </template>
                                        </Column>
                                        <Column field="refCode" header="Referencia">
                                          <template #editor="slotProps">
                                            <span class="p-input-icon-right" style="width:100%">
                                                <i class="pi pi-refresh" style="cursor: pointer" @click="regenerateRefCode(slotProps.data.key)" />
                                                <InputText class="input"  type="text" v-model="slotProps.data[slotProps.column.props.field]" />
                                            </span>
                                          </template>
                                        </Column>
                                        <Column alignFrozen="right">
                                          <template #body="slotProps">
                                              <ion-buttons style="justify-content: flex-end">
                                                  <ion-button  @click="removeOption($event, slotProps.data.key)" >
                                                      <ion-icon slot="icon-only" :icon="removeCircleOutline"></ion-icon>
                                                  </ion-button>
                                              </ion-buttons>
                                          </template>
                                        </Column>
                                    </DataTable>
                                </div>
                            </template>
                        </DataTable>
                        </ion-col>
                      </ion-row>

                      <ion-row class="mb-md">
                        <h5 class="edit-title">Especificaciones</h5>
                          <ion-col size="12">
                            <ion-item lines="none">
                                <ion-label><b>Especificaciones del Producto</b></ion-label>
                                <ion-buttons slot="end">
                                    <ion-button @click="addSpecification" >
                                        <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                                    </ion-button>
                                </ion-buttons>
                            </ion-item>

                          <DataTable :value="element.specifications" @cell-edit-complete="onCellEditSpecificationsComplete" dataKey="id" editMode="cell" class="specifications p-datatable-sm edit-datatable"
                              @rowReorder="reorderSpecifications"  responsiveLayout="scroll">
                              <template #empty>
                                  No hay especificaciones de este producto
                              </template>
                              <Column :rowReorder="true" :reorderableColumn="false" style="width: 64px; cursor: move;" />
                              <Column field="text" header="Especificación">
                                <template #editor="slotProps">
                                    <InputText class="input" v-model="slotProps.data[slotProps.column.props.field]" />
                                </template>
                              </Column>
                              <Column field="value" header="Valor">
                                <template #editor="slotProps">
                                    <InputText class="input" v-model="slotProps.data[slotProps.column.props.field]" />
                                </template>
                              </Column>
                              <Column alignFrozen="right">
                                  <template #body="slotProps"> 
                                        <ion-buttons style="justify-content: flex-end">
                                          <ion-button  @click="removeSpecification($event, slotProps.index)" >
                                              <ion-icon slot="icon-only" :icon="removeCircleOutline"></ion-icon>
                                          </ion-button>
                                      </ion-buttons>
                                  </template>
                              </Column>
                              
                          </DataTable>
                          </ion-col>
                      </ion-row>

                      <ion-row class="mb-md">
                        <h5 class="edit-title">Oferta</h5>

                        <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.element.offer.discount.$errors.length }">
                            <label for="discount">Descuento</label>
                            <InputNumber id="discount" class="inputNumber secondary-input" v-model.lazy="v$.element.offer.discount.$model" :min="0" :max="100" suffix="%" showButtons  />

                            <!-- error message -->
                            <div class="input-errors" v-for="(error, index) of v$.element.offer.discount.$errors" :key="index">
                            <h6 class="error-msg">{{ error.$message }}</h6>
                            </div>
                        </ion-col>


                        <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.element.offer.durationRange.$errors.length }">
                            <label for="durationRange">Duracción</label>
                            <Calendar autocomplete="off" id="durationRange" class="input" v-model.lazy="v$.element.offer.durationRange.$model" selectionMode="range" :manualInput="false" :minDate="new Date()" />

                            <!-- error message -->
                            <div class="input-errors" v-for="(error, index) of v$.element.offer.durationRange.$errors" :key="index">
                            <h6 class="error-msg">{{ error.$message }}</h6>
                            </div>
                        </ion-col>

                        <ion-col size="12">
                          <ion-item lines="none">
                            <label>Oferta Acumulable</label>
                            <ion-toggle v-model="element.offer.acumulativeDiscount" slot="end" color="primary"></ion-toggle>
                          </ion-item>
                        </ion-col>
                      </ion-row>

                      <ion-row class="mb-md">
                        <h5 class="edit-title">Etiquetas</h5>
                          <ion-col size="12">
                                  <ion-segment scrollable :value="selectedBarcode" mode="md" color="primary" @ionChange="barcodeSegmentChanged($event)">
                                    <ion-segment-button value="barcode">
                                      <ion-label>Código de Barras</ion-label>
                                    </ion-segment-button>
                                    <ion-segment-button value="qr">
                                      <ion-label>QR</ion-label>
                                    </ion-segment-button>
                                  </ion-segment>

                                  <div :class="(selectedBarcode === 'barcode') ? '' : 'hidden'">
                                    <div style="width: 100%">
                                      <canvas id="barcode" style="margin: 0.5rem 0;"></canvas>
                                    </div>
                                    <ion-button shape="round" color="primary" @click="customBarcode = !customBarcode" style="margin-right: 0.5rem; margin-bottom: 1rem">
                                      <ion-label>Personalizar</ion-label>
                                    </ion-button>
                                    <ion-button shape="round" color="dark" fill="outline" @click="downloadBarcode" style="margin-bottom: 1rem">
                                      <ion-label>Imprimir</ion-label>
                                    </ion-button>

                                      <div v-if="customBarcode" class="codePersonalization">
                                        <label for="refCode">Código de Barras</label>
                                        <InputText class="input" id="refCode" type="text" v-model="barcodeCode" />

                                        <label for="barcodeFormat">Formato</label>
                                          <Dropdown v-model="barcodeFormat" :placeholder="'Por defecto: ' + barcodeFormat" class="inputSelect" :options="barcodeFormats" optionLabel="label" optionGroupLabel="label" optionGroupChildren="items">
                                            <template #optiongroup="slotProps">
                                                <div class="p-d-flex p-ai-center country-item">
                                                    <span style="font-weight: bold;">{{slotProps.option.label}}</span>
                                                </div>
                                            </template>
                                        </Dropdown>

                                        <ion-button shape="round" color="primary" @click="regenerateBarcode" style="margin-top: 1rem">
                                          <ion-label>Generar código de barras</ion-label>
                                        </ion-button>
                                      </div>
                                  </div>

                                <div :class="(selectedBarcode === 'qr') ? '' : 'hidden'">
                                  <div id="qrcode" style="margin: 0.5rem 0;"></div>

                                  <ion-button shape="round" color="primary" @click="customQRCode = !customQRCode" style="margin-right: 0.5rem; margin-bottom: 1rem">
                                    <ion-label>Personalizar</ion-label>
                                  </ion-button>
                                  <ion-button shape="round" color="dark" fill="outline" @click="downloadQRCode" style="margin-bottom: 1rem">
                                    <ion-label>Imprimir</ion-label>
                                  </ion-button>

                                    <div v-if="customQRCode" class="codePersonalization">
                                      <label for="urlCode">Url</label>
                                      <InputText class="input" id="urlCode" type="text" v-model="qrcodeCode" />

                                      <ion-button shape="round" color="primary" @click="regenerateQRCode" style="margin-top: 1rem">
                                        <ion-label>Generar QR</ion-label>
                                      </ion-button>
                                    </div>
                                </div>
                          </ion-col>
                        </ion-row>
                    </ion-grid>
                  </ion-col>

                  <ion-col size="12" size-lg="4" class="hide-xs hide-sm hide-md">
                      <DishPreview :data="element"></DishPreview>
                  </ion-col>

                </ion-row>
              </ion-grid>
          </section>
        </div>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonSegment, IonSegmentButton, IonToggle, modalController, IonIcon, IonLabel, IonButton, IonButtons, IonItem } from '@ionic/vue';
import { saveOutline, trashOutline, arrowBackOutline, moveOutline, addCircleOutline, removeCircleOutline, closeCircleOutline } from 'ionicons/icons';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Chips from 'primevue/chips';
import Editor from 'primevue/editor';
import FileUpload from 'primevue/fileupload';
import ConfirmPopup from 'primevue/confirmpopup';
import InputNumber from 'primevue/inputnumber';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Image from 'primevue/image';
import Calendar from 'primevue/calendar';

import { defineComponent } from 'vue';
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import DishPreview from './components/DishPreview.vue';

import { VueDraggableNext } from 'vue-draggable-next'

import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/i18n-validators'

import { mapActions, mapState } from 'vuex';

import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode'
import printJS from 'print-js'

export default defineComponent({
  name: 'EditProduct',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    Footer,
    Dropdown,
    InputText,
    Chips,
    Editor,
    FileUpload,
    ConfirmPopup,
    InputNumber,
    DataTable,
    DishPreview,
    Column,
    Image,
    Calendar,
    IonSegment,
    IonSegmentButton,
    IonToggle,
    IonIcon,
    IonLabel,
    IonButton, 
    IonButtons,
    IonItem,
    Header,
    draggable: VueDraggableNext,
  },
  data() {
      return {
        element: {
          keywords: [],
          variations: [],
          specifications: [],
          images: [],
          ratings: [],
          globalRating: 0,
          description: '',
          offer: {},
          price: 0,
          cost: 0,
        },
        language: 'es',
        images: [],

        dialogImageUrl: '',
        dialogVisible: false,
        languages: [
                {name: 'Español', code: 'es'},
        ],
        lastVariationKey: 0,
        expandedRows: [],

        selectedBarcode: 'barcode',
        customBarcode: false,
        barcodeCode: '',
        barcodeFormat: 'EAN13',
        barcodeFormats: [{
                            label: 'CODE128', code: 'CODE128', 
                            items: [
                                {label: 'CODE128 (automatic mode switching)', value: 'CODE128'},
                                {label: 'CODE128 A (force mode)', value: 'CODE128A'},
                                {label: 'CODE128 B (force mode)', value: 'CODE128B'},
                                {label: 'CODE128 C (force mode)', value: 'CODE128C'}
                            ]
                        },
                        {
                            label: 'EAN', code: 'EAN', 
                            items: [
                                {label: 'EAN-13', value: 'EAN13'},
                                {label: 'EAN-8', value: 'EAN8'},
                                {label: 'EAN-5', value: 'EAN5'},
                                {label: 'EAN-2', value: 'EAN2'},
                                {label: 'UPC', value: 'UPC'},
                            ]
                        },
                        {
                            label: 'CODE39', code: 'CODE39', 
                            items: [
                                {label: 'CODE39', value: 'CODE39'},
                            ]
                        },
                        {
                            label: 'ITF', code: 'ITF', 
                            items: [
                                {label: 'ITF-14', value: 'ITF14'},
                            ]
                        },
                        {
                            label: 'MSI', code: 'MSI', 
                            items: [
                                {label: 'MSI10', value: 'MSI10'},
                                {label: 'MSI11', value: 'MSI11'},
                                {label: 'MSI1010', value: 'MSI1010'},
                                {label: 'MSI1110', value: 'MSI1110'},
                            ]
                        },
                        {
                            label: 'Pharmacode', code: 'pharmacode', 
                            items: [
                                {label: 'Pharmacode', value: 'pharmacode'},
                            ]
                        },
                        {
                            label: 'Codabar', code: 'codabar', 
                            items: [
                                {label: 'Codabar', value: 'codabar'},
                            ]
                        },
                        ],
        customQRCode: false,
        qrcodeCode: '',
      };
    },
    validations() {
        return {
            element: {
                name: { required },
                refCode: {  },
                category: { required },
                subcategory: { },
                price: { required },
                cost: { },
                stock: {  },
                description: {},
                variations: {},
                specifications: {},
                keywords: {},
                offer:{
                  discount: {},
                  durationRange: {},
                  acumulativeDiscount: {}
                }
            },
        }
    },
        computed: {
        ...mapState('shop', {
            product: state => state.product,
            productsCategories: state => state.productsCategories,
        }),

        ...mapState(['evolbeUser', 'preferences'])
    },
    methods: {
      ...mapActions('shop',['getProduct','uploadImage', 'addProduct', 'updateProduct', 'deleteProduct', 'getProductsCategories']),
      ...mapActions(['uploadImage', 'uploadImageMenu']),

      // Images
      handleImageUpload(event){
        event.files.forEach(file => {
          const epoch = new Date().getTime();
          file.id = epoch + '-' + Math.floor(Math.random() * 1000000);
          
          this.images.push(file);
          this.uploadImage({ruta: '/evolbe-modules/shop/products', img: file});
          this.element.images.push({
            '256': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Fshop%2Fproducts%2Fthumbnails%2F' + file.id + '_256x256.webp?alt=media',
            '512': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Fshop%2Fproducts%2Fthumbnails%2F' + file.id + '_512x512.webp?alt=media',
            '1024': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Fshop%2Fproducts%2Fthumbnails%2F' + file.id + '_1024x1024.webp?alt=media',
            '2048': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Fshop%2Fproducts%2Fthumbnails%2F' + file.id + '_2048x2048.webp?alt=media',
          })
        });

        this.$refs.uploader.clear();
      },
      handleImageRemove(event, url) {
        const pos0 = this.images.findIndex(x => x.objectURL === url);
        this.images.splice(pos0, 1);

        const pos = this.element.images.findIndex(x => x[512] === url);
        this.element.images.splice(pos, 1);
      },
      handleImagePreview(event, url) {
        this.dialogImageUrl = url;
        this.dialogVisible = true;
      },

      updateImageList(event){
        const temp = this.element.images[event.moved.oldIndex];
        this.element.images.splice(event.moved.oldIndex, 1);
        this.element.images.splice(event.moved.newIndex, 0, temp);
      },

      // RefCode
      regenerateRefCode(value){
        if(value === 'main'){
          this.element.refCode = Math.random().toString(20).substr(2, 6);
        } else {
          const variationKey = value.split('-')[0];
          const posVariation = this.element.variations.findIndex(x => x.key === parseInt(variationKey));
          const posOption = this.element.variations[posVariation].options.findIndex(x => x.key === value);
          this.element.variations[posVariation].options[posOption].refCode = Math.random().toString(20).substr(2, 6);
        }
      },

      // Variations
      addVariation(){
        if(!this.element.variations){
          this.element.variations = [];
        }
        this.element.variations.push({
            "key": (this.lastVariationKey + 1),
            "name":"Nueva Variación",
            "lastOptionKey": 0,
            "options": [
                {
                    "key": (this.lastVariationKey + 1) + "-0",
                    "text": "Nueva Opción",
                    "price": 0,
                    "stock": 0,
                    "refCode": 'Ref.'
                },
            ]
        });

        this.lastVariationKey = this.lastVariationKey + 1;
      },
      removeVariation(event, key){
        const pos = this.element.variations.findIndex(x => x.key === key);
        this.element.variations.splice(pos, 1);

        this.lastVariationKey = this.lastVariationKey - 1;
      },
      addOption(event, key){
        const pos = this.element.variations.findIndex(x => x.key === key);
        if(!this.element.variations[pos].options){
          this.element.variations[pos].options = [];
        }

        this.element.variations[pos].options.push({
            "key": key + "-" + (this.element.variations[pos].lastOptionKey + 1),
            "text": "Nueva Opción",
            "price": 0,
            "stock": 0,
            "refCode": 'Ref.'
        });

        this.element.variations[pos].lastOptionKey = this.element.variations[pos].lastOptionKey + 1;
      },
      removeOption(event, key){
        const variationKey = key.split('-')[0];
        const posVariation = this.element.variations.findIndex(x => x.key === parseInt(variationKey));
        const posOption = this.element.variations[posVariation].options.findIndex(x => x.key === key);
        this.element.variations[posVariation].options.splice(posOption, 1);
      },

      // Specifications
      addSpecification(){
        if(!this.element.specifications){
          this.element.specifications = [];
        }

        this.element.specifications.push({
          text: 'Especificación',
          value: 'Valor'
        })
      },
      removeSpecification(event, pos){
        this.element.specifications.splice(pos, 1);
      },
      reorderSpecifications(event){
        this.element.specifications = event.value;
      },

      // Tags
      regenerateBarcode(){
        JsBarcode("#barcode", this.barcodeCode, {format: this.barcodeFormat, flat: true});
        this.element.barcode = this.barcodeCode;
      },
      downloadBarcode(){
        printJS({
          printable: "barcode",
          type: "html"
        });
      },
      regenerateQRCode(){
        QRCode.toCanvas(this.qrcodeCode, { errorCorrectionLevel: 'M', width: 200, margin: 2 }, function (err, canvas) {
          if (err) throw err
        
          const container = document.getElementById('qrcode');
          container.innerHTML = '';
          container.appendChild(canvas);
        });
        this.element.qr = this.qrcodeCode;
      },
      downloadQRCode(){
         printJS({
          printable: "qrcode",
          type: "html"
        });
      },
      barcodeSegmentChanged(event){
        this.selectedBarcode = event.detail.value;
      },

      onCellEditVariationComplete(event){
        this.element.variations[event.index][event.field] = event.newValue;
      },

      onCellEditOptionComplete(event, slotProps){
        this.element.variations[slotProps.index].options[event.index][event.field] = event.newValue;
      },

      onCellEditSpecificationsComplete(event){
        this.element.specifications[event.index][event.field] = event.newValue;
      },

      // Actions
      save(){
        if(this.$route.params.id === 'new'){
          this.element.dateCreated = new Date().getTime();
          this.element.dateUpdated = new Date().getTime();

          this.addProduct(this.element);
          this.exit();
        } else {
          this.element.dateUpdated = new Date().getTime();
          this.updateProduct({data: this.element, id: this.$route.params.id});
          this.exit();
        }
      },
      confirmDeleteElement(event){
        this.$confirm.require({
                target: event.currentTarget,
                message: '¿Seguro de que quieres eliminar ese elemento?',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when evolbeUser confirms the action
                    this.deleteProduct(this.$route.params.id);
                    this.exit();
                },
                reject: () => {
                    //callback to execute when evolbeUser rejects the action
                }
            });
         
      },

      exit(){
          this.images = [];
          this.$router.go(-1);
      },

      async showPreview(){
        const modal = await modalController
            .create({
                component: DishPreview,
                cssClass: 'auto-height',
                componentProps: {
                    element: this.element
                },
            })
        return modal.present();
      }
    },
    beforeMount() {
      if(this.$route.params.id === 'new'){
        this.lastVariationKey = 0;

        // Initialice Codes
        setTimeout(() => {
          const datetime = new Date().getTime().toString();
          const code = datetime.substring(1);
          JsBarcode("#barcode", code, {format: "EAN13", flat: true});
          this.barcodeCode = code;
          this.element.barcode = this.barcodeCode;

          const url = this.evolbeUser.business[0].url + '/products/' + this.element.objectID;
          QRCode.toCanvas(url, { errorCorrectionLevel: 'M', width: 200, margin: 2 }, function (err, canvas) {
            if (err) throw err

            const container = document.getElementById('qrcode');
            container.appendChild(canvas);
          });
          this.qrcodeCode = url;
          this.element.qr = this.qrcodeCode;
        }, 1000);

      } else {
        this.getProduct(this.$route.params.id).then(() => {
            if(this.product){
              // Load Product
              this.element = this.product;

                // Load Images
                if(this.element && !this.images.length){
                  this.element.images.forEach(img => {
                    this.images.push({objectURL: img['1024'], status: 'upload'});
                  });
                }

                // Initialice Variations
                if(this.element.variations){
                  this.lastVariationKey = this.element.variations.length;
                } else{
                  this.lastVariationKey = 0;
                }

                // Initialice Codes
                if(this.element.barcode){
                  JsBarcode("#barcode", this.element.barcode, {format: "EAN13", flat: true});
                } else {
                  const datetime = new Date().getTime().toString();
                  const code = datetime.substring(1);
                  JsBarcode("#barcode", code, {format: "EAN13", flat: true});
                  this.barcodeCode = code;
                  this.element.barcode = this.barcodeCode;
                }

                if(this.element.qr){
                  QRCode.toCanvas(this.element.qr, { errorCorrectionLevel: 'H', width: 200, margin: 2 }, function (err, canvas) {
                    if (err) throw err

                    const container = document.getElementById('qrcode');
                    container.appendChild(canvas);
                  })
                } else {
                  const url = this.evolbeUser.business[0].url + '/products/' + this.element.objectID;
                  QRCode.toCanvas(url, { errorCorrectionLevel: 'M', width: 200, margin: 2 }, function (err, canvas) {
                    if (err) throw err
        
                    const container = document.getElementById('qrcode');
                    container.appendChild(canvas);
                  });
                  this.qrcodeCode = url;
                  this.element.qr = this.qrcodeCode;
                }
          }
        });
      }

      this.getProductsCategories();
    },
  setup() {
    return { v$: useVuelidate(), saveOutline, trashOutline, arrowBackOutline, moveOutline, addCircleOutline, removeCircleOutline, closeCircleOutline};
  }
});
</script>

<style scoped>

  .edit-title{
    padding-bottom: 1rem;
    width: 100%;
  }

  .imagenes{
    margin-top: 1rem;
    display: flex;
    overflow: hidden;
    display: grid;
  }

  .imagenes .imagen-container{
    position: relative;
    margin: 0.5rem
  }

  .imagenes .imagen-container .imagen{
    width: 100%;
    height: 212px;
    border-radius: 1rem;
    overflow: hidden;
  }

  .imagenes .imagen-container .imagen-remover{
    position: absolute;
    top: 8px;
    right: 8px;
  }

</style>