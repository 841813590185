<template>
  <div class="inner-preview">
      <ion-card id="preview">
        <ion-card-content>
            <h5 class="edit-title">Previsualización</h5>
            <p><i>*La previsualización no está disponible en estos momentos.</i></p>
        </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import { IonCard, IonCardContent} from '@ionic/vue';
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'Products',
  components: {
    IonCard,
    IonCardContent
  },
});
</script>

<style scoped>
</style>